<template>
  <div>
    <Breadcrumbs title="Profile"/>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form class="card" @submit.prevent="updateDetails">
            <div class="card-header">
              <h5>Account Details</h5><span>Manage your user account</span>
            </div>
            <div class="card-body">

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Username <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.username" class="form-control"></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>First Name <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.first_name" class="form-control"></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Last Name <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.last_name" class="form-control"></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>NIC <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.nic" class="form-control" minlength="10" maxlength="12" required></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Mobile <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.phone_mobile" class="form-control" minlength="9" maxlength="9" required></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Phone (Home)</strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.phone_home" class="form-control" minlength="9" maxlength="10"></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Email Address</strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.email" class="form-control"></b-form-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Address Line 1</strong></div>
                <div class="col-sm-9">
                  <b-form-textarea v-model="user.address_line_1" class="form-control"></b-form-textarea>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Address Line 2</strong></div>
                <div class="col-sm-9">
                  <b-form-textarea v-model="user.address_line_2" class="form-control"></b-form-textarea>
                </div>
              </div>



              <div class="card-footer text-center">
                <b-button type="submit" class="m-r-10">Update</b-button>
                <b-button @click="resetFormData" class="btn btn-light">Reset</b-button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'profile',
  computed: mapGetters({ user: 'auth/user' }),
  created() { this.$store.dispatch('auth/getUser') },
  methods: {
    async resetFormData() {
      await this.$store.dispatch('auth/getUser')
    },
    async updateDetails() {
      try {
        let resp = await this.$store.dispatch('auth/updateProfile', this.user)
        await this.$toasted.success("Profile updated successfully")
        console.log(resp)
      } catch (e) {
        // await this.$toasted.error("[ ERROR " + e.response.status + " ] Request Failed")
        // await this.$toasted.error("[ ERROR " + e.response.status + " ] " + e.response.message)
        console.log(e)
        await this.$toasted.error(e.message)
      }
    }
  }
}
</script>

<style scoped>

</style>
